import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { MutationResult, ExecutionResult } from 'react-apollo';

import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
import { defaultSchema as schema } from '@atlaskit/adf-schema/schema-default';

import { CreatePageMutation } from './CreatePageMutation.graphql';

type CreatePageParams = {
	pageTitle: string;
	spaceKey: string;
	parentId: string;
	content: string;
};

export type UseCreatePageReturnType = [
	createPage: (params: CreatePageParams) => Promise<ExecutionResult<any>>,
	mutationResult: MutationResult,
];

export const useCreatePage = (): UseCreatePageReturnType => {
	const [createPresentationMutation, mutationResults] = useMutation(CreatePageMutation);

	const createPage = useCallback(
		({ pageTitle, spaceKey, parentId, content }: CreatePageParams) => {
			const markdown = new MarkdownTransformer(schema).parse(content);
			const adf = new JSONTransformer().encode(markdown);

			return createPresentationMutation({
				variables: {
					spaceKey,
					parentId,
					pageTitle,
					pageContent: JSON.stringify(adf),
				},
			});
		},
		[createPresentationMutation],
	);

	return [createPage, mutationResults];
};
