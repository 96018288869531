import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Inline, Stack, Text } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';

import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';
import { EDIT_PAGE_V2 } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

import { useCreatePage } from './useCreatePage';

const i18n = defineMessages({
	modalTitle: {
		id: 'quick-summary.create-page-modal.title',
		defaultMessage: 'Select location',
		description: 'title for the create page modal',
	},
	modalBody: {
		id: 'quick-summary.create-page-modal.body',
		defaultMessage: 'Select the space and parent item for your generated page',
		description: 'title for the create page modal',
	},
	cancelButton: {
		id: 'quick-summary.create-page-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'button to close the create page modal',
	},
	confirmButton: {
		id: 'quick-summary.create-page-modal.confirm-button',
		defaultMessage: 'Confirm',
		description: 'button to submit the create page modal and create a new page',
	},
});

export type CreatePageModalProps = {
	onClose: () => void;
	summaryText: string;
};

export const CreatePageModal: FC<CreatePageModalProps> = ({ onClose, summaryText }) => {
	const { formatMessage } = useIntl();

	const [createPage, { loading }] = useCreatePage();

	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();

	const { push } = useRouteActions();

	const handleCreatePage = async () => {
		if (contentId && spaceKey) {
			try {
				const result = await createPage({
					parentId: contentId,
					spaceKey,
					pageTitle: 'this is a placeholder title',
					content: summaryText,
				});

				const draftUrl = EDIT_PAGE_V2.toUrl({
					contentType: 'pages',
					spaceKey,
					contentId: result.data.newPage.page.id,
				});
				push(draftUrl);
			} catch (e) {
				// noop, will handle errors in follow up PR
			}
		}
	};

	return (
		<ModalTransition>
			<Modal onClose={onClose}>
				<ModalHeader>
					<ModalTitle>{formatMessage(i18n.modalTitle)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Stack>
						<Text>{formatMessage(i18n.modalBody)}</Text>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Inline space="space.100">
						<Button appearance="subtle" onClick={onClose}>
							{formatMessage(i18n.cancelButton)}
						</Button>
						<Button appearance="primary" isLoading={loading} onClick={handleCreatePage}>
							{formatMessage(i18n.confirmButton)}
						</Button>
					</Inline>
				</ModalFooter>
			</Modal>
		</ModalTransition>
	);
};
