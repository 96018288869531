import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';

import { CreatePageModal } from './CreatePageModal';

const i18n = defineMessages({
	createPageButton: {
		id: 'quick-summary.create-page-button',
		defaultMessage: 'Create page',
		description: 'Button in quick summary footer to launch modal for converting summary to page',
	},
});

export type CreatePageButtonProps = {
	summaryText: string;
};

export const CreatePageButton: FC<CreatePageButtonProps> = ({ summaryText }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = useCallback(() => setIsModalOpen(true), []);
	const closeModal = useCallback(() => setIsModalOpen(false), []);

	const { formatMessage } = useIntl();

	return (
		<>
			<Button appearance="primary" onClick={openModal}>
				{formatMessage(i18n.createPageButton)}
			</Button>
			{isModalOpen && <CreatePageModal onClose={closeModal} summaryText={summaryText} />}
		</>
	);
};
